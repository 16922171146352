import styled from 'styled-components';
import 'typeface-vt323'

import BasicP from '../P';

const P = styled(BasicP)`
  margin: 0;
  font-family: "vt323";

`;

export default P;